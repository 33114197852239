//用户名匹配
export function nameRule(rule, value, callback) {
    // 请输入4~16位用户名
    let reg = /^[a-zA-Z0-9-_@.]{0,32}$/
    if (value === '') {
        callback(new Error('请输入用户名'))
    } else if (!reg.test(value)) {
        callback(new Error('请输入正确的用户名或邮箱'))
    } else {
        callback()
    }
}

//密码匹配
export function passRule(rule, value, callback) {
    // 请输入6~18位用户名
    let pass = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]/
    if (value === '') {
        callback(new Error('请输入密码'))
    } else if (!pass.test(value)) {
        // callback(new Error('请输入6~18位密码。需要包含大写，小写，数字，特殊符号其中任意三项密码。'))
    } else {
        callback()
    }
}